<template>
  <GameStocksMain />
</template>

<script>
import axios from "axios";
import GameStocksMain from "@/components/game/profile/GameStocksMain";

export default {
  name: "GameStocks",
  components: {
    GameStocksMain,
  },
};
</script>

<style>
</style>