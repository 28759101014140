<template>
  <div class="app-stocks">
    <div class="back" @click="goBack">
      <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 1L1 8L8 15" stroke="white" stroke-width="2" stroke-linejoin="round"/>
      </svg>
    </div>
    <Preloader v-if="!loading" />
    <div class="portrait" v-if="orientation === 'portrait' && loading">
      <div class="container" v-if="!stock">
        <div class="title">Розыгрыши</div>
        <div class="stocks">
          <div class="stock flex" v-for="stock of stocks" :key="stock._id">
            <div class="info">
              <div>{{ stock.name }}</div>
              <div>до {{ stock.end_date | dateServerFilter }}</div>
            </div>
            <button class="btn btn-disabled" v-if="stock.memberStatus" @click="openStock(stock)">Вы участник</button>
            <button class="btn btn-gradient" v-else @click="openStock(stock)">Участвовать</button>
          </div>
        </div>
      </div>
      <div class="container" v-if="stock">
        <div class="title">
          <div>{{ stock.name }}</div>
          <div class="date">до {{ stock.end_date | dateServerFilter }}</div>
        </div>
        <div class="description">{{ stock.description }}</div>
        <div class="action">
          <div class="btn btn-disabled btn-div" v-if="stock.memberStatus">
            <span>Вы уже участвуете в этом розыгрыше</span>
          </div>
          <button class="btn btn-gradient" v-else @click="selectStock">Хочу участвовать</button>
        </div>
      </div>
    </div>
    <div class="landscape" v-if="orientation === 'landscape' && loading">
      <div class="container" v-if="!stock">
        <div class="title">Розыгрыши</div>
        <div class="stocks">
          <div class="stock flex" v-for="stock of stocks" :key="stock._id">
            <div class="info">
              <div>{{ stock.name }}</div>
              <div>до {{ stock.end_date | dateServerFilter }}</div>
            </div>
            <button class="btn btn-disabled" v-if="stock.memberStatus" @click="openStock(stock)">Вы участник</button>
            <button class="btn btn-gradient" v-else @click="openStock(stock)">Участвовать</button>
          </div>
        </div>
      </div>
      <div class="container container__stock" v-if="stock">
        <div class="title">
          <div>{{ stock.name }}</div>
          <div class="date">до {{ stock.end_date | dateServerFilter }}</div>
        </div>
        <div class="description">{{ stock.description }}</div>
        <div class="action">
          <div class="btn btn-disabled btn-div" v-if="stock.memberStatus">
            <span>Вы уже участвуете в этом розыгрыше</span>
          </div>
          <button class="btn btn-gradient" v-else @click="selectStock">Хочу участвовать</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Preloader from '../Preloader';
import Axios from 'axios';

export default {
  name: 'GameStocksMain',
  data() {
    return {
      orientation: this.$store.getters.orientation,
      loading: false,
      stocks: null,
      stock: null,
      user: this.$store.getters.user,
      token: this.$store.getters.token,
    }
  },
  mounted() {
    Axios
      .get(`/api/stocks`, {
        headers: {
          token: this.token
        }
      })
      .then(res => {
        res.data.data.forEach(el => {
          el.memberStatus = el.members.find(m => m === this.user._id) ? true : false;
        })
        this.stocks = res.data.data;
        this.loading = true;
      })
      .catch(err => {
        console.error(err.response);
      })
  },
  methods: {
    openStock(stock) {
      this.stock = stock;
    },
    goBack() {
      if (this.stock) {
        this.stock = null;
      } else {
        this.$router.push('/profile')
      }
    },
    selectStock() {
      Axios
        .put(`/api/stock/member`, {_id: this.stock._id}, {
          headers: {
            token: this.token
          }
        })
        .then(res => {
          this.stock.memberStatus = true;
          this.stock = null;
        })
        .catch(err => {
          console.error(err.response);
        })
    }
  },
  watch: {
    '$store.state.orientation': function() {
      if (this.$store.state.orientation) {
        this.orientation = this.$store.state.orientation;
      }
    }
  },
  components: {
    Preloader
  }
}
</script>

<style lang="scss" scoped>
.back {
  position: absolute;
  top: 25px;
  left: 25px;
  cursor: pointer;
}
.app-stocks {
  .landscape {
    .container {
      padding-top: 32px;
      padding-left: 20px;
      .title {
        font-size: 22px;
      }
      .stock {
        width: 80%;
        justify-content: space-between;
        .info {
          flex-basis: none;
        }
        button {
          width: fit-content;
          padding: 0 45px;
        }
      }
    }
    .container__stock {
    }
  }
  .container {
    padding-top: 100px;
    .title {
      font-size: 30px;
      margin-bottom: 20px;
      .date {
        font-size: 15px;
        color: #C5C5C5;
      }
    }
    .description {
      margin-bottom: 20px;
      line-height: 1.3;
    }
    .stocks {
      .stock {
        margin-bottom: 15px;
        .info {
          display: flex;
          justify-content: center;
          flex-direction: column;
          flex-basis: 100%;
          div:first-child {
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 3px;
          }
          div:last-child {
            color: #AEADB1;
            font-size: 12px;
          }
        }
        button {
          height: 40px !important;
        }
      }
    }
  }
}
</style>